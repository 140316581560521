import { FormProvider, useForm } from '@graphcommerce/ecommerce-ui'
import { Trans } from '@lingui/react'
import { useState } from 'react'
import IconUser from '../icons/IconUser'
import IconUserAdd from '../icons/IconUserAdd'
import CodeForm from './CodeForm'
import LoginForm from './LoginForm'
import SendCodeForm from './SendCodeForm'
import SignupForm from './Signup'

export enum FormType {
  login = 'login',
  signup = 'signup',
  code = 'code',
  sendcode = 'sendcode',
}

type AuthFormsProps = {
  activeForm?: FormType
  stayAtPage?: boolean
  hideTabs?: boolean
  showLinks?: boolean
}

// eslint-disable-next-line import/no-default-export
export default function AuthForms({
  activeForm: activeFormProp = FormType.login,
  stayAtPage,
  hideTabs = false,
  showLinks = false,
}: AuthFormsProps) {
  const [activeForm, setActiveForm] = useState(activeFormProp)
  const [activeTab, setActiveTab] = useState(activeFormProp)
  const [data, setData] = useState({})
  const methods = useForm()

  const handleLoginClick = () => {
    if (activeForm === FormType.signup) {
      setActiveForm(FormType.login)
      setActiveTab(FormType.login)
    }
  }

  const handleSignupClick = () => {
    if (activeForm === FormType.login || activeForm === FormType.code) {
      setActiveForm(FormType.signup)
      setActiveTab(FormType.signup)
    }
  }

  const loginButtonClass = `flex justify-center w-1/2 h-12 items-center ${
    activeTab === FormType.login
      ? 'bg-[#46ba71] text-white *:fill-[#fff]'
      : 'bg-light-170 text-[#333] *:fill-[#333]'
  } md:rounded-s-md`
  const signupButtonClass = `flex justify-center w-1/2 h-12 items-center ${
    activeTab !== FormType.login
      ? 'bg-[#46ba71] text-white *:fill-[#fff]'
      : 'bg-light-170 text-[#333] *:fill-[#333]'
  } md:rounded-e-md`

  return (
    <div className='992:w-[420px] relative min-h-[10rem] max-md:flex max-md:flex-col'>
      {!hideTabs && (
        <div className='w-full md:absolute -top-10 md:-left-1/2 md:translate-x-1/2 md:translate-y-1/2 flex justify-center 992:px-5'>
          <button type='button' className={loginButtonClass} onClick={handleLoginClick}>
            <IconUser className='ml-1' width={15} height={15} />
            <span className='text-sm font-medium'>
              <Trans id='Sign in' />
            </span>
          </button>

          <button type='button' className={signupButtonClass} onClick={handleSignupClick}>
            <IconUserAdd className='ml-1' width={15} height={15} />
            <span className='text-sm font-medium'>
              <Trans id='Register' />
            </span>
          </button>
        </div>
      )}
      <div className='p-4'>
        <FormProvider {...methods}>
          {activeForm === FormType.login && (
            <LoginForm
              showLinks={showLinks}
              setActiveForm={setActiveForm}
              stayAtPage={stayAtPage}
            />
          )}
          {activeForm === FormType.signup && (
            <SignupForm showLinks={showLinks} setActiveForm={setActiveForm} setData={setData} />
          )}
          {activeForm === FormType.code && (
            <CodeForm setActiveForm={setActiveForm} setData={setData} />
          )}
          {activeForm === FormType.sendcode && (
            <SendCodeForm
              setActiveForm={setActiveForm}
              data={data}
              setData={setData}
              stayAtPage={stayAtPage}
            />
          )}
        </FormProvider>
      </div>
    </div>
  )
}
