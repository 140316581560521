// icon:user-add | Entypo http://entypo.com/ | Daniel Bruce
import * as React from 'react'

function IconUserAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill='currentColor'
      width='1em'
      height='1em'
      viewBox='0 0 355 320'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M355 182.531H310.469V138H285.531V182.531H241V207.469H285.531V252H310.469V207.469H355V182.531Z' />
      <path d='M267.3 267.9C254.4 263.3 235.9 261.7 224.1 259.1C217.3 257.6 207.4 253.8 204.1 249.9C200.8 245.9 202.8 209 202.8 209C202.8 209 208.9 199.4 212.2 191C215.5 182.6 219.1 159.6 219.1 159.6C219.1 159.6 225.9 159.6 228.3 147.7C230.9 134.7 234.9 129.3 234.4 119.6C233.9 110.6 229.2 110.1 228.7 110.1C228.7 110.1 233.6 96.5 234.3 67.7C235.1 33.6 209 0 160 0C111 0 85 33.5 85.7 67.6C86.3 96.3 91.3 110 91.3 110C90.8 110 86.1 110.5 85.6 119.5C85.1 129.2 89.2 134.4 91.7 147.4C94.1 159.3 100.9 159.4 100.9 159.4C100.9 159.4 104.5 182.5 107.8 190.9C111.1 199.4 117.2 208.9 117.2 208.9C117.2 208.9 119.2 245.8 115.9 249.8C112.6 253.8 102.7 257.5 95.9 259C84 261.6 65.6 263.3 52.7 267.9C39.8 272.5 0 288 0 320H160H320C320 288 280.2 272.5 267.3 267.9Z' />
    </svg>
  )
}

export default IconUserAdd
