import { useFormContext } from '@graphcommerce/ecommerce-ui'
import { useMutation, useQuery } from '@graphcommerce/graphql'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Button } from '@mui/material'
import Link from 'next/link'
import { useContext, useState } from 'react'
import { useLoading } from '../../context/loadingContext'
import { createAccountOTPDocument } from '../../graphql/login/createAccountOtp.gql'
import { createCustomerAccountMutationVariables } from '../../graphql/login/createCustomerAccount.gql'
import { isNewCustomerDocument } from '../../graphql/login/isNewCustomer.gql'
import IconLogin from '../icons/IconLogin'
import BrithDayField from '../widgets/Form/BirthDayField'
import { CheckboxElement } from '../widgets/Form/CheckboxElement'
import RadioGroupElement from '../widgets/Form/RadioGroupElement'
import { TextFieldElement } from '../widgets/Form/TextFieldElement'
import CloseBtn from './CloseBtn'
// eslint-disable-next-line import/no-cycle
import { FormType } from '.'
import { useRouter } from 'next/router'
import { ModalContext } from '../../context/context'

// eslint-disable-next-line import/no-default-export
export default function SignupForm({
  setActiveForm,
  setData,
  showLinks = false,
}: {
  setActiveForm: (a: FormType) => void
  setData: (data: any) => void
  showLinks?: boolean
}) {
  const router = useRouter()
  const { close } = useContext(ModalContext)
  const [newCustomerError, setNewCustomerError] = useState<any>(null)
  const { refetch } = useQuery(isNewCustomerDocument, { skip: true })
  const [createAccountOTP] = useMutation(createAccountOTPDocument)
  const methods = useFormContext()
  const { setLoading } = useLoading()

  const submitHandler = async (values: any) => {
    const {
      firstname = i18n._('user'),
      lastname = i18n._('Dear'),
      email,
      password,
      date_of_birth,
      gender,
      mobileNumber,
    } = values as createCustomerAccountMutationVariables

    setLoading(true)
    await refetch({
      mobile: mobileNumber,
    }).then(async ({ data: isNewCustomer }) => {
      if (isNewCustomer.isNewCustomer) {
        await createAccountOTP({
          variables: {
            mobileNumber,
          },
        }).then((res) => {
          if (!res.errors) {
            const useEmail = email && email.length > 0 ? email : `${mobileNumber}@ordme.com`
            setData({
              mode: 'createAccount',
              firstname,
              lastname,
              mobileNumber,
              email: useEmail,
              password,
              date_of_birth,
              gender: Number(gender),
            })
            setNewCustomerError(null)
            setActiveForm(FormType.sendcode)
            setLoading(false)
          }
        })
      } else {
        setLoading(false)
        setNewCustomerError(true)
      }
    })
  }
  return (
    <div className='pt-2 md:pt-5'>
      {newCustomerError && (
        <div className='text-sm text-error-500 font-normal text-center my-4'>
          <Trans id='This mobile phone is already registered' />
          <Link className='text-primary-500 font-normal' href='/account/forgot-password'>
            <Trans id='Password recovery' />
          </Link>
        </div>
      )}
      <p className='text-base text-[#333] font-semibold xl:mt-8 mb-2'>
        <Trans id='Create Account' />
      </p>
      <form onSubmit={methods.handleSubmit(submitHandler)}>
        <div className='w-full max-992:mt-6'>
          <RadioGroupElement
            row
            name='gender'
            defaultValue='2'
            labelSx={{ marginLeft: '0.5rem' }}
            radioGroupSx={[
              { '& > .MuiFormControlLabel-root': { marginLeft: 0 } },
              { '& .MuiButtonBase-root.MuiRadio-root': { padding: '5px' } },
              { '& > .MuiFormControlLabel-root:first-child': { marginRight: 0 } },
            ]}
            radioGroupSize='small'
            label={i18n._('gender')}
            items={[
              { label: <span style={{ fontSize: '13px' }}>{i18n._('Female')}</span>, value: '2' },
              { label: <span style={{ fontSize: '13px' }}>{i18n._('male')}</span>, value: '1' },
            ]}
          />
        </div>

        <div className='w-full mt-7 mb-3'>
          <BrithDayField
            name='date_of_birth'
            control={methods.control}
            required
            defaultValue='1360/06/06'
          />
        </div>

        <TextFieldElement
          name='mobileNumber'
          label={i18n._('mobile number')}
          type='tel'
          required
          placeholder='برای مثال *********09 '
          fullWidth
          variant='standard'
          sx={[
            { marginBottom: '24px' },
            { '& .MuiFormLabel-root.MuiInputLabel-root': { fontSize: '14px', color: '#5d5d5d' } },
            {
              '& input::placeholder': {
                textAlign: 'right',
                direction: 'rtl',
              },
            },
          ]}
          className='!text-sm appearance-none'
          color='primary'
        />

        <TextFieldElement
          name='email'
          label={i18n._('Email (optional)')}
          type='email'
          fullWidth
          variant='standard'
          sx={[
            { marginBottom: '24px' },
            { '& .MuiFormLabel-root.MuiInputLabel-root': { fontSize: '14px', color: '#5d5d5d' } },
          ]}
          className='!text-sm'
          color='primary'
        />

        <TextFieldElement
          name='password'
          type='password'
          label={i18n._('Your favorite password (at least 7 characters)')}
          validation={{
            minLength: {
              value: 7,
              message: i18n._('Password is at least 7 characters long.'),
            },
          }}
          required
          fullWidth
          sx={[
            { marginBottom: '40px' },
            { '& .MuiFormLabel-root.MuiInputLabel-root': { fontSize: '14px', color: '#5d5d5d' } },
          ]}
          className='!text-sm'
          variant='standard'
          color='primary'
        />

        <div className='flex w-full items-center justify-start gap-2'>
          <CheckboxElement
            required
            checked
            rules={{
              required: i18n._('Items with an asterisk are required.'),
            }}
            name='aggreement'
            classes={{ root: '!mr-[-1.2rem]' }}
          />
          <span className='text-sm text-[#333]'>
            <Link
              onClick={(e) => {
                e.preventDefault()
                router.push('/pages/rules')
                close()
              }}
              href='/pages/rules'
              className='text-primary-500'
            >
              {i18n._('Terms and conditions')}
            </Link>
            <span> </span>
            {i18n._("I have read and agree to the site's terms of use.")}
          </span>
        </div>

        <div className='flex justify-center items-center mt-6'>
          <button type='submit' className='ordme_btn flex items-center justify-center'>
            <IconLogin width={15} height={15} className='ml-1' />
            <Trans id='Save' />
          </button>
        </div>
        {showLinks && (
          <span className='text-slate-800 text-sm flex justify-center mt-6'>
            {' '}
            اگر قبلا در سایت ثبت نام کرده اید
            <Link className='text-primary-500 hover:text-primary-700' href='/account/login'>
              .از اینجا وارد شوید
            </Link>
          </span>
        )}
        <CloseBtn />
      </form>
    </div>
  )
}
