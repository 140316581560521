import { useFormContext } from '@graphcommerce/ecommerce-ui'
import { useMutation, useQuery } from '@graphcommerce/graphql'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Button } from '@mui/material'
import { useContext, useEffect, useState, useCallback } from 'react'
import { ModalContext } from '../../context/context'
import { useLoading } from '../../context/loadingContext'
import { createAccountOTPVerifyDocument } from '../../graphql/login/createAccountOtpVerify.gql'
import { createCustomerAccountDocument } from '../../graphql/login/createCustomerAccount.gql'
import { loginOTPVerifyDocument } from '../../graphql/login/loginOTPVerify.gql'
import IconArrowLeftShort from '../icons/IconArrowLeftShort'
import IconPen from '../icons/IconPen'
import { TextFieldElement } from '../widgets/Form/TextFieldElement'
import useLogin from './useLogin'
import { loginOTPDocument } from '../../graphql/login/loginOTP.gql'
import { FormType } from './index'

type SendCodeFormProps = {
  setActiveForm: (formType: FormType) => void
  data: any
  stayAtPage?: boolean
  setData: (data: any) => void
}

const timerForSendCode = 120

const SendCodeForm = ({ setActiveForm, data, setData, stayAtPage }: SendCodeFormProps) => {
  const [counter, setCounter] = useState(timerForSendCode)
  const useModal = useContext(ModalContext)
  const { refetch } = useQuery(createAccountOTPVerifyDocument, { skip: true })
  const { refetch: verifyCode } = useQuery(loginOTPVerifyDocument, { skip: true })
  const [createCustomerAccount] = useMutation(createCustomerAccountDocument)
  const methods = useFormContext()
  const { setLoading } = useLoading()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const { handleLogin } = useLogin()
  const [loginOtp] = useMutation(loginOTPDocument)

  useEffect(() => {
    if (counter <= 0) return
    const timer = setTimeout(() => setCounter((prevCounter) => prevCounter - 1), 1000)
    return () => clearTimeout(timer)
  }, [counter])

  const handleSubmit = useCallback(
    async (values) => {
      setLoading(true)
      try {
        if (data.mode === 'createAccount') {
          const { data: verifyData, errors } = await refetch({
            mobileNumber: data.mobileNumber,
            otp: values.otp,
          })
          if (errors) throw new Error(verifyData?.createAccountOTPVerify?.message)
          if (!verifyData?.createAccountOTPVerify?.status) {
            setErrorMessage(verifyData?.createAccountOTPVerify.message)
            return
          }
          const { data: accountData } = await createCustomerAccount({
            variables: {
              firstname: data.firstname,
              lastname: data.lastname,
              date_of_birth: data.date_of_birth,
              email: data.email,
              gender: data.gender,
              mobileNumber: data.mobileNumber,
              password: data.password,
              otp: values.otp,
            },
          })
          if (
            accountData?.createCustomerAccount?.status &&
            accountData.createCustomerAccount.token
          ) {
            // createWPAccount({email:data.email,password:data.password,username:data.mobileNumber})
            setSuccessMessage(i18n._('The registration operation was completed successfully.'))
            handleLogin(accountData.createCustomerAccount.token, null, true, stayAtPage)
          }
        } else if (data.mode === 'loginOtp') {
          const { data: loginData, errors } = await verifyCode({
            mobileNumber: data.mobileNumber,
            otp: values.otp,
          })
          if (errors) throw new Error(loginData?.loginOTPVerify?.message)
          if (loginData?.loginOTPVerify?.status && loginData.loginOTPVerify.token) {
            handleLogin(loginData.loginOTPVerify.token)
            setSuccessMessage(i18n._('Login was successful.'))
            setErrorMessage(null)
          } else {
            setErrorMessage(loginData?.loginOTPVerify?.message)
          }
        }
      } catch (error) {
        setErrorMessage(error.message)
      } finally {
        setLoading(false)
      }
    },
    [createCustomerAccount, data, handleLogin, refetch, setLoading, stayAtPage, verifyCode],
  )

  const sendCodeAgain = async () => {
    const mobileNumber = data?.mobileNumber || null

    if (mobileNumber) {
      setLoading(true)
      await loginOtp({
        variables: {
          mobileNumber: data?.mobileNumber,
        },
      })
        .then((res) => {
          const { data } = res
          if (data?.loginOTP?.status === true) {
            setData({ mobileNumber, mode: 'loginOtp' })
            setActiveForm(FormType.sendcode)
            setCounter(timerForSendCode)
          } else {
            setErrorMessage(i18n._('There was an error sending the one-time password.'))
          }
        })
        .catch((err) => {
          setErrorMessage(i18n._('There was an error sending the one-time password.'))
        })
        .finally(() => setLoading(false))
    } else {
      setErrorMessage(i18n._('There was an error sending the one-time password.'))
    }
  }

  return (
    <div className='pt-2 md:pt-5'>
      {errorMessage && (
        <div className='text-sm text-error-500 font-normal text-center my-4'>{errorMessage}</div>
      )}
      {successMessage && (
        <div className='text-sm text-secondary-300 font-normal text-center my-4'>
          {successMessage}
        </div>
      )}
      <p className='text-base text-[#333] font-semibold xl:mt-8 mb-2'>
        <Trans id='Verification of mobile phone number' />
      </p>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <p className='max-992:text-sm text-xs mt-5 text-ordme-gray-500'>
          <Trans id='Please enter the code sent via SMS in the field below:' />
        </p>
        <TextFieldElement
          name='otp'
          label={i18n._('5 digit code')}
          required
          fullWidth
          type='otp'
          variant='standard'
          classes={{ root: '!mt-5' }}
          className='!text-sm'
          color='primary'
          sx={[
            { '& .MuiFormLabel-root.MuiInputLabel-root': { fontSize: '14px', color: '#5d5d5d' } },
          ]}
        />
        <div className='flex justify-center items-center mt-8 text-sm'>
          {counter > 0 ? (
            <p className='text-ordme-gray-500 font-light'>
              {i18n._('Ability to resend the code up to')}{' '}
              <span className='font-semibold'>{counter}</span> {i18n._('Another second')}
            </p>
          ) : (
            <button
              disabled={counter !== 0}
              type='button'
              className='font-medium flex items-center justify-center text-primary-500 hover:text-ordme-gray-400 min-h-9 px-2 hover:bg-[#9e9e9e33]'
              onClick={sendCodeAgain}
            >
              <Trans id='Resend the code' />
            </button>
          )}
        </div>
        <div className='flex justify-center items-center mt-8'>
          <button
            type='submit'
            className='ordme_btn ordme_btn_success'
          >
            <Trans id='Final approval' />
          </button>
        </div>
        <div className='mt-8 flex justify-start'>
          <button
            type='button'
            className='text-sm text-ordme-gray-400 px-2 min-h-9 hover:bg-[#9e9e9e33] font-medium rounded-sm flex items-center justify-center'
            onClick={() =>
              setActiveForm(data?.mode === 'createAccount' ? FormType.signup : FormType.code)
            }
          >
            <IconPen width={14} height={14} className='ml-2 fill-ordme-gray-400' />
            <Trans id='Change mobile phone number' />
          </button>
          <button
            type='button'
            className='text-sm text-ordme-gray-400 px-2 min-h-9 hover:bg-[#9e9e9e33] font-medium rounded-sm flex items-center justify-center mr-6'
            onClick={useModal.close}
          >
            <Trans id='Close' />
            <IconArrowLeftShort width={18} height={18} className='mr-2' />
          </button>
        </div>
      </form>
    </div>
  )
}

export default SendCodeForm
