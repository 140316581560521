import { Control, Controller, FieldValues, useFormContext } from '@graphcommerce/ecommerce-ui'
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  RadioPropsSizeOverrides,
  SxProps,
  Theme,
} from '@mui/material'
import { useEffect } from 'react'

type RadioGroupElementProps = {
  name: string
  control?: Control<FieldValues>
  items: { label: string; value: string }[]
  label?: string
  center?: boolean
  itemProps?: RadioProps
  formControlSx?: SxProps<Theme>
  labelSx?: SxProps<Theme>
  radioGroupSx?: SxProps<Theme>
  radioGroupSize?: 'small' | 'medium'
  formControlLabelSx?: SxProps<Theme>
  classes?: FormControlLabelProps['classes']
} & Omit<RadioGroupProps, 'sx'>
export default function RadioGroupElement({
  name,
  label,
  items,
  center,
  control,
  defaultValue,
  formControlSx,
  labelSx,
  radioGroupSx,
  radioGroupSize,
  formControlLabelSx,
  itemProps,
  ...props
}: RadioGroupElementProps) {
  const { setValue, control: contextControl } = useFormContext()
  useEffect(() => {
    if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
      setValue(name, defaultValue)
    }
  }, [defaultValue])
  return (
    <Controller
      name={name}
      control={contextControl}
      render={({ field: { name, ...fieldProps } }) => (
        <FormControl
          className={`flex !flex-row !items-center !flex-nowrap ${center && '!justify-center'}`}
          fullWidth
          required
          sx={formControlSx}
        >
          {label && (
            <FormLabel
              id='demo-form-control-label-placement'
              sx={[
                { color: '#ff0000', marginLeft: '1.5rem' },
                ...(Array.isArray(labelSx) ? labelSx : [labelSx]),
              ]}
            >
              <span className='text-ordme-gray-300 text-[14px]'>{label}</span>
            </FormLabel>
          )}
          <RadioGroup sx={radioGroupSx} {...fieldProps} {...props} name={name}>
            {items.map((item, index) => (
              <FormControlLabel
                classes={props.classes}
                key={index}
                value={item.value}
                checked={item.value === fieldProps.value}
                control={<Radio size={radioGroupSize} {...itemProps} />}
                label={item.label}
                sx={formControlLabelSx}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}
