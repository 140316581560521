import * as React from 'react'

function IconPen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height='1em'
      width='1em'
      viewBox='0 0 384 384'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 304V384H80L315.727 148.271L235.728 68.273L0 304ZM377.602 86.398C386.133 77.867 386.133 65.064 377.602 56.533L327.467 6.398C318.936 -2.133 306.133 -2.133 297.602 6.398L258.134 45.867L338.133 125.865L377.602 86.398Z'
      />
    </svg>
  )
}

export default IconPen
